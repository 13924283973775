/* PROFILE PAGE - entire page*/
.Profile {
    /* background: lightblue; */
    display: flex;
    margin: 2% 4% 0 4%;
    height: 100%;
}
@media only screen and (max-width: 500px) {
    .Profile {
        display: flex;
        /* flex-direction: column-reverse; */
    }
}



/* RIGHT SIDE -Holds all the form info*/

.Rightside {
    width: 75%;
}


/* HOLDS formLeft and formRight */
.formholder {
    display: flex;
    flex-wrap: wrap;
    /* height: 100%;  */
}

@media only screen and (max-width: 500px) {
    .formholder {
      padding-top: 15px;
    
    }
  }


/* Default Input field */
.formLeft {
    width: 60%;
    /* border: 2px solid black; */
}

.input {
    width: 90%;
    padding: 5px;
    display: flex;
    margin: 5px 0 2px 0;
    border: 1px solid #0066FF;
    border-radius: 5px;


}


/* Hobbie textarea */
.formRight {
    width: 40%;
}

.input2 {
    border: 1px solid #0066FF;
    width: 90%;
    margin: 5px 0 2px 0;
    height: 85%;
    border-radius: 5px;

}

/* User Profile Image, and friends list*/

.Leftside {
    /* border: 2px solid black; */
    width: 50%;
}


/* Profile Image box */
.Userimgbox {
    height: 125px;
    width: 125px;
    border: 2px solid #0066FF;
    border-radius: 5px;
    margin: 10px;
}


/* FRIENDS LIST- lists all users friends */
.Friend-list {
    margin: 10px;
    border: 2px solid #0066FF;
    border-radius: 5px;
    height: 125px;
    width: 80%;
}

.FriendCard {
    background: #0066FF;

}