.Navbar {
  background: #0066FF;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.Title {
  padding-top: 10px;
  display: flex;
  justify-content: center;


}

.navbaritems {
  /* padding-top: 25px; */
  /* border: 2px solid black; */

  display: flex;
  justify-content: center;

}


.navlink {

  background-color: white;
  color: #3498DB;
  padding: 8px;
  font-size: 15px;
  font-weight: bold;
  margin: 10px;
  border-radius: 5px;
  border: none;
}

.button {
  background-color: white;
  color: #3498DB;
  padding: 8px;
  font-size: 15px;
  font-weight: bold;
  margin: 10px;
  border-radius: 5px;
  border: none;
}


@media only screen and (max-width: 800px) {
  .navlink {

    padding: 8px;
    display: flex;
    flex-direction: column-reverse;



  }

  .button {
    width: 96%;
    padding: 8px;
    display: flex;
    flex-direction: column-reverse;
  }

  .navbaritems {
    display: inline-block;

  }


}


.navlink:hover {
  background-color: rgb(0, 225, 255);
  color: white;
  text-decoration: none;

}

.active {
  /* background: red; */
  background: rgb(0, 225, 255);
  color: white;
  text-decoration: none;

}

h1 {
  color: white;
}

img {
  width: 50px;
  height: 50px;
  padding: 4px;
  margin-right: 4px;
}