.footer {
  background: #0066FF;
  color: white;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding-top: 16px;


}